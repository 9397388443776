




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class TheFooter extends Vue {
  @Prop({ default: 'v1.4.4' }) version!: string
  items = []

  logout() {
    this.$store.dispatch('logout')
    this.$router.push({ name: 'Login' })
  }

  get username() {
    return this.$store.getters.loggedUser.username
  }

  get mode(): string {
    return process.env.VUE_APP_MODE || ''
  }

  get role(): string {
    const role = this.$store.getters.loggedUser.role
    return this.$t(`roles.${role}`).toString()
  }
}
